import React from "react";
import { Link } from "react-router-dom";

import PageBanner from "./../layouts/PageBanner";
import UpdateBlog from "../components/Home/UpdateBlog";

import bg from "./../assets/images/gstvk/gstvk21.jpg";
import team1 from "./../assets/images/donghanh/xuanphuong_avatar.JPG";
import team2 from "./../assets/images/donghanh/kimcuong.png";
import team3 from "./../assets/images/donghanh/vukimhanh.jpg";
import team4 from "./../assets/images/donghanh/mythuhuyen.jpg";
import team5 from "./../assets/images/donghanh/buitranphuong.jpg";
import team6 from "./../assets/images/donghanh/nguyenthihau.jpg";
import team7 from "./../assets/images/donghanh/quachthunguyet.jpg";
import team8 from "./../assets/images/donghanh/dinhthithanhthuy.jpg";
import team9 from "./../assets/images/donghanh/trandinhson.JPG";
import team10 from "./../assets/images/donghanh/thanhhiep.jpg";
import team11 from "./../assets/images/donghanh/leminhquoc.jpg";
import team12 from "./../assets/images/donghanh/thanhloc.jpg";
import team13 from "./../assets/images/donghanh/kimtulong.jpeg";
import team14 from "./../assets/images/donghanh/kimtieulong.JPG";
import team15 from "./../assets/images/donghanh/chunghoangchuong.jpg";
import team16 from "./../assets/images/donghanh/user.png";
import team17 from "./../assets/images/donghanh/hotuong.JPG";
import team18 from "./../assets/images/donghanh/nhutdung.jpg";
import team19 from "./../assets/images/donghanh/huynhkhai.jpg";
import team20 from "./../assets/images/donghanh/user.png";
import whitebg from "./../assets/images/donghanh/white.jpg";
import shape2 from "../assets/images/pattern/shape2.png";
import shape3 from "../assets/images/pattern/shape3.png";
import shape5 from "../assets/images/pattern/shape5.png";
import shape1 from "../assets/images/pattern/shape1.png";
import shape6 from "../assets/images/pattern/shape6.png";

const cardDataBlog = [
  {
    image: team1,
    title: "Nguyễn Thị Xuân Phượng",
    subtitle: "Đạo diễn - Nhà văn ",
  },
  { image: team2, title: "Kim Cương", subtitle: "Nghệ sĩ nhân dân" },
  { image: team3, title: "Vũ Kim Hạnh", subtitle: "Nhà báo" },
  {
    image: team4,
    title: "Phạm Dương Mỹ Thu Huyền",
    subtitle: "Nguyên giám đốc bảo tàng TPHCM",
  },
  {
    image: team5,
    title: "Bùi Trân Phượng",
    subtitle: "Tiến sĩ, nguyên hiệu trưởng Đại học Hoa Sen",
  },
  { image: team6, title: "Nguyễn Thị Hậu", subtitle: "Tiến sĩ" },
  {
    image: team7,
    title: "Quách Thu Nguyệt",
    subtitle: "Tiến sĩ, nguyên giám đốc NXB Trẻ, Chủ tịch Quỹ Hoa Sen",
  },
  {
    image: team8,
    title: "Đinh Thị Thanh Thủy",
    subtitle: "Giám đốc NXB Tổng hợp TPHCM",
  },
  { image: team9, title: "Trần Đình Sơn", subtitle: "Nhà Nguyên Cứu" },
  {
    image: team10,
    title: "Nguyễn Thanh Hiệp",
    subtitle: "Nhà báo - Đạo diễn, báo Người Lao Động",
  },
  { image: team11, title: "Lê Minh Quốc", subtitle: "Nhà báo" },
  { image: team12, title: "Thành Lộc", subtitle: "Nghệ sĩ ưu tú" },
  { image: team13, title: "Kim Tử Long", subtitle: "Nghệ sĩ ưu tú" },
  { image: team14, title: "Kim Tiểu Long", subtitle: "Nghệ sĩ ưu tú" },
  { image: team15, title: "Chung Hoàng Chương", subtitle: "Giáo sư" },
  {
    image: team16,
    title: "Võ Thị Xuân Mai",
    subtitle: "Nguyên phó trưởng phòng Thu thập Trung tâm Lưu trữ Quốc gia II",
  },
  { image: team17, title: "Hồ Tường", subtitle: "Tiến sĩ" },
  { image: team18, title: "Nhứt Dũng", subtitle: "Ban nhạc nghệ sĩ" },
  // {image:team19, title:"Huỳnh Khải", subtitle:"Ban nhạc NSUT"},
  {
    image: team20,
    title: "Châu Minh Tâm",
    subtitle: "Giảng viên nhạc viện TPHCM",
  },
];

const Partner = () => {
  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dz-bnr-inr dz-bnr-inr-sm style-1 text-center"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="dz-bnr-inr-entry">
              {/* <h1>HỌC BỔNG GIẢI THƯỞNG TRẦN VĂN KHÊ 2023</h1> */}
              {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>{maintitle}</Link></li>
                                <li className="breadcrumb-item active">{pagetitle}</li>
                            </ul>
                        </nav> */}
            </div>
          </div>
        </div>
        <section
          className="content-inner bg-light section-pattren1"
          style={{ paddingTop: "50px", paddingBottom: "20px" }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">ĐỒNG HÀNH</h2>
            </div>
          </div>
          {/* <img src={shape1} className="shape-1 move-1" alt="shape" /> */}
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          {/* <img src={shape6} className="shape-5 rotating" alt="shape" /> */}
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
        <section className="content-inner-2" style={{ marginBottom: "40px" }}>
          <div className="container">
            <div className="row">
              {cardDataBlog.map((item, ind) => (
                <div className="col-xl-3 col-lg-4 col-sm-6" key={ind}>
                  <div className="dz-team style-1 m-b30 tvk">
                    <div className="dz-media">
                      <Link to={"#"}>
                        <img src={item.image} alt="" />
                      </Link>
                      {/* <ul className="team-social">
                                                <li><a href="https://www.facebook.com/" target="_blank" rel="noreferrer" className="fab fa-facebook-f"></a></li>
                                                <li><a href="https://www.instagram.com/" target="_blank" rel="noreferrer" className="fab fa-instagram"></a></li>
                                                <li><a href="https://twitter.com/" target="_blank" rel="noreferrer" className="fab fa-twitter"></a></li>
                                            </ul> */}
                    </div>
                    <div className="dz-content">
                      <h5 className="dz-name">{item.title}</h5>
                      <span className="dz-position text-primary">
                        {item.subtitle}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-3 col-lg-4 col-sm-6" key={20}>
                <div className="dz-team style-1 m-b30 tvk">
                  <div className="dz-media">
                    <Link to={"#"}>
                      <img src={whitebg} alt="" />
                      <img
                        style={{
                          position: "absolute",
                          height: "auto",
                          left: "0",
                          top: "12%",
                        }}
                        src={team19}
                        alt=""
                      />
                    </Link>
                    {/* <ul className="team-social">
                                                <li><a href="https://www.facebook.com/" target="_blank" rel="noreferrer" className="fab fa-facebook-f"></a></li>
                                                <li><a href="https://www.instagram.com/" target="_blank" rel="noreferrer" className="fab fa-instagram"></a></li>
                                                <li><a href="https://twitter.com/" target="_blank" rel="noreferrer" className="fab fa-twitter"></a></li>
                                            </ul> */}
                  </div>
                  <div className="dz-content">
                    <h5 className="dz-name">Huỳnh Khải</h5>
                    <span className="dz-position text-primary">
                      Ban nhạc NSUT
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="call-action style-1 content-inner-1">
                    <div className="container">
                        <UpdateBlog />
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default Partner;
