import React from "react";
import { Link } from "react-router-dom";

import Pagebanner from "../layouts/PageBanner";
import CategoriesIcon from "../components/CategoriesIcon";

//images
import bg from "../assets/images/banner/bnr4.jpg";
import shape2 from "../assets/images/pattern/shape2.png";
import shape3 from "../assets/images/pattern/shape3.png";
import shape5 from "../assets/images/pattern/shape5.png";
import shape1 from "../assets/images/pattern/shape1.png";
import shape6 from "../assets/images/pattern/shape6.png";
import large1 from "../assets/images/project/large/pic1.jpg";
import large2 from "../assets/images/project/large/pic2.jpg";
import large3 from "../assets/images/project/large/pic3.jpg";
import sign from "../assets/images/about/signature.png";
import sponsorLogo from "../assets/images/gstvk/logo-vlu-color.png";
import fessiorLogo from "../assets/images/gstvk/logo-fessior.png";
import sponsorbanner from "../assets/images/gstvk/sponsorbanner.jpg";
import fessior from "../assets/images/gstvk/gdsc.jpg";

import OurMission from "../components/OurMission";
import UpdateBlog from "../components/Home/UpdateBlog";
import Donation from "../components/Donation";

const Sponsor = () => {
  function isScrolledIntoView(elem) {
    const spliBox = document.querySelectorAll(elem);
    spliBox.forEach(myFunction);
    function myFunction(item, index) {
      console.log("splitbox", item);
      const docViewTop = window.scrollY;
      const docViewBottom = docViewTop + window.innerHeight;
      let elemTop = item.getBoundingClientRect().top + docViewTop;
      const elemBottom = elemTop + item.offsetHeight;
      if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
        item.classList.add("split-active");
      }
    }
  }
  window.addEventListener("scroll", () => {
    isScrolledIntoView(".split-box");
  });
  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dz-bnr-inr dz-bnr-inr-sm style-1 text-center"
          style={{
            backgroundImage: "url(" + sponsorbanner + ")",
          }}
        >
          <div className="container">
            <div className="dz-bnr-inr-entry">
              {/* <h1>HỌC BỔNG GIẢI THƯỞNG TRẦN VĂN KHÊ 2023</h1> */}
              {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>{maintitle}</Link></li>
                                <li className="breadcrumb-item active">{pagetitle}</li>
                            </ul>
                        </nav> */}
            </div>
          </div>
        </div>
        <section
          className="content-inner bg-light section-pattren1"
          style={{ paddingTop: "50px", paddingBottom: "20px" }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">ĐỐI TÁC</h2>
            </div>
          </div>
          {/* <img src={shape1} className="shape-1 move-1" alt="shape" /> */}
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          {/* <img src={shape6} className="shape-5 rotating" alt="shape" /> */}
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
        {/* <section className="content-inner">
                    <div className="container">
                        <div className="section-head text-center">
                            <h5 className="sub-title">CATEGORIES</h5>
                            <h2 className="title">Explore Our Crowdfunding <br/> Featured Categories</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <div className="row">
                            <CategoriesIcon />
                        </div>
                    </div>
                </section> */}
        {/* <section className="content-inner-2 bg-light section-pattren1">
                    <div className="container">
                        <div className="row about-bx3 align-items-center">
                            <OurMission />
                        </div>
                    </div>
                    <img src={shape2} className="shape-6 move-2" alt="shape"/>
                    <img src={shape3} className="shape-5 move-1" alt="shape"/>
                    <img src={shape5} className="shape-1 rotating" alt="shape"/>
                    <img src={shape1} className="shape-3 move-1" alt="shape"/>
                    <img src={shape6} className="shape-4 rotating" alt="shape"/>
                    <img src={shape6} className="shape-2 rotating" alt="shape"/>
                </section> */}
        <section className="content-inner">
          <div className="container">
            <div className="row align-items-center project-bx left m-b30">
              <div className="col-lg-6 col-md-12">
                <div className="dz-media">
                  <div>
                    <img
                      src={
                        "https://reviewedu.net/wp-content/uploads/2021/09/hoc-phi-dai-hoc-van-lang.jpg"
                      }
                      alt=""
                      className="app-1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="dz-content">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <h3 className="title">Nhà tài trợ</h3>
                      <h2 className="title m-b15">
                        Đại học
                        <br /> Văn Lang
                      </h2>
                    </div>
                    <img
                      className="vlu-logo"
                      src={sponsorLogo}
                      alt="Profile Pic"
                      style={{ marginLeft: "16px" }}
                    />
                  </div>
                  <p className="m-b0" style={{ marginTop: "16px" }}>
                    <strong style={{ color: "#1B8271", fontWeight: "600" }}>
                      Lan tỏa tác động truyền cảm hứng
                    </strong>
                    <br />
                    Mang lại tác động truyền cảm hứng cho xã hội chính là sứ
                    mệnh của Văn Lang. Kết nối nhiều lĩnh vực khác nhau, hòa
                    trộn những bản sắc độc đáo, Văn Lang đã xây dựng nên một
                    cộng đồng học thuật phong phú, lớn mạnh, khát khao tìm kiếm
                    tri thức để tạo nên sự đổi mới, đột phá sáng tạo và một thế
                    giới tốt đẹp hơn.
                  </p>
                  <ul
                    className="list-inline m-b0"
                    style={{ display: "flex", marginTop: "16px" }}
                  >
                    <li>
                      <a
                        style={{
                          width: "35px",
                          height: "35px",
                          color: "white",
                          backgroundColor: "rgb(27, 130, 113)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: "6px",
                          marginRight: "10px",
                        }}
                        href="https://www.facebook.com/truongdaihocvanlang"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-link"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        style={{
                          width: "35px",
                          height: "35px",
                          color: "white",
                          backgroundColor: "rgb(27, 130, 113)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: "6px",
                          marginRight: "10px",
                        }}
                        href="mailto: truyenthong@vlu.edu.vn"
                        rel="noreferrer"
                        className="btn-link"
                      >
                        <i className="fa-solid fa-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        style={{
                          width: "35px",
                          height: "35px",
                          color: "white",
                          backgroundColor: "rgb(27, 130, 113)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: "6px",
                        }}
                        href="https://www.vlu.edu.vn/"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-link"
                      >
                        <i class="fa-brands fa-google"></i>
                      </a>
                    </li>
                    {/* <li><a style={{backgroundColor: '#FF7468'}} href="https://www.youtube.com" target="_blank"  rel="noreferrer"  className="btn-link"><i className="fab fa-youtube"></i></a></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row align-items-center project-bx right">
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-content">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <h3 className="title">Phát triển Web</h3>
                      <h2 className="title m-b15">Fessior</h2>
                    </div>
                    <img
                      className="fessior-logo"
                      src={fessiorLogo}
                      alt="Profile Pic"
                      style={{ marginLeft: "16px" }}
                    />
                  </div>
                  {/* <p className="m-b0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit au</p>
                                    <Link to={"/project-story"} className="btn btn-primary m-t30">View More <i className="fa-solid fa-angle-right ms-2"></i></Link> */}
                  <p className="m-b0" style={{ marginTop: "16px" }}>
                    <strong style={{ color: "#1B8271", fontWeight: "600" }}>
                      Rút ngắn khoảng cách giữ lý thuyết và thực tiễn
                    </strong>
                    <br />
                    Fessior là một ban thuộc câu lạc bộ Google Developer Group
                    on Campus - Đại học Bách khoa, ĐHQG TP.HCM. Sứ mệnh của
                    Fessior là phát triển các dự án về công nghệ nhằm mang lại
                    những giá trị thiết thực cho xã hội và xây dựng nên một cộng
                    đồng những sinh viên yêu thích công nghệ.
                  </p>
                  <ul
                    className="list-inline m-b0"
                    style={{ display: "flex", marginTop: "16px" }}
                  >
                    <li>
                      <a
                        style={{
                          width: "35px",
                          height: "35px",
                          color: "white",
                          backgroundColor: "rgb(27, 130, 113)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: "6px",
                          marginRight: "10px",
                        }}
                        href="https://www.facebook.com/dscxhcmut"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-link"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        style={{
                          width: "35px",
                          height: "35px",
                          color: "white",
                          backgroundColor: "rgb(27, 130, 113)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: "6px",
                          marginRight: "10px",
                        }}
                        href="mailto: contact@gdschcmut.dev"
                        rel="noreferrer"
                        className="btn-link"
                      >
                        <i className="fa-solid fa-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        style={{
                          width: "35px",
                          height: "35px",
                          color: "white",
                          backgroundColor: "rgb(27, 130, 113)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: "6px",
                          marginRight: "10px",
                        }}
                        href="https://discord.com/invite/AujNumHzcF"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-link"
                      >
                        <i class="fa-brands fa-discord"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        style={{
                          width: "35px",
                          height: "35px",
                          color: "white",
                          backgroundColor: "rgb(27, 130, 113)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: "6px",
                        }}
                        href="https://www.linkedin.com/company/gdschcmut"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-link"
                      >
                        <i class="fa-brands fa-linkedin"></i>
                      </a>
                    </li>
                    {/* <li><a style={{backgroundColor: '#FF7468'}} href="https://www.youtube.com" target="_blank"  rel="noreferrer"  className="btn-link"><i className="fab fa-youtube"></i></a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media">
                  <div>
                    <img src={fessior} alt="" className="app-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={shape1} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>

        <section
          className="content-inner"
          style={{ paddingTop: "40px", paddingBottom: 0, marginBottom: "32px" }}
        >
          <div className="container">
            <Donation />
          </div>
        </section>

        {/* <section className="content-inner-2">
                    <div className="container">
                        <div className="row align-items-center project-bx left m-b30">
                            <div className="col-lg-6 col-md-12">	
                                <div className="dz-media split-box">
                                    <div>
                                        <img src={large3} alt="" className="app-1" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="dz-content">
                                    <h2 className="title m-b15">The Goal</h2>
                                    <p className="m-b0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit au</p>
                                    <div className="signature m-t30">
                                        <img src={sign} alt="image" />
                                        <p className="text-dark m-t20 mb-0">William Durant</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
        {/* <div className="call-action style-1 content-inner-1">
          <div className="container">
            <UpdateBlog />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Sponsor;
