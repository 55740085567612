import React from "react";
import { Link } from "react-router-dom";

import PageBanner from "./../layouts/PageBanner";
import NhomThanHuuTVK from "../components/NhomThanHuuTVK";

//images
import bgImage from "./../assets/images/gstvk/info.jpg";
import gstvk14 from "./../assets/images/gstvk/gstvk14.jpg";
import gstvk15 from "./../assets/images/gstvk/gstvk15.jpg";
import gstvk16 from "./../assets/images/gstvk/gstvk16.jpg";
import gstvk17 from "./../assets/images/gstvk/gstvk17.jpg";
import gstvk18 from "./../assets/images/gstvk/gstvk18.jpg";
import gstvk7 from "./../assets/images/gstvk/gstvk7.webp";
import prizeIcon from "./../assets/icons/gstvk/prize.png";
import activityIcon from "./../assets/icons/gstvk/activity.png";
import tickIcon from "./../assets/icons/gstvk/tick.png";
import bg4 from "./../assets/images/background/bg4.jpg";
import shape2 from "../assets/images/pattern/shape2.png";
import shape3 from "../assets/images/pattern/shape3.png";
import shape5 from "../assets/images/pattern/shape5.png";
import shape1 from "../assets/images/pattern/shape1.png";
import shape6 from "../assets/images/pattern/shape6.png";
import large2 from "../assets/images/project/large/pic2.jpg";
import large3 from "../assets/images/project/large/pic3.jpg";
import sign from "../assets/images/about/signature.png";

import NewsSlider from "../components/Home/NewsSlider";
import UpdateBlog from "../components/Home/UpdateBlog";
import OurMission from "../components/OurMission";
import { IMAGES } from "../constant/theme";

const iconBlog = [
  {
    icon: prizeIcon,
    description:
      "Xét và tặng Giải thưởng Trần Văn Khê, Học bổng Trần Văn Khê hàng năm",
  },
  {
    icon: activityIcon,
    description:
      "Phối hợp với các đơn vị báo chí, xuất bản, nghiên cứu, đào tạo, biểu diễn nghệ thuật tổ chức các hoạt động đúng tôn chỉ, mục đích của Quỹ, trong đó có việc gây quỹ để duy trì Quỹ",
  },
  {
    icon: tickIcon,
    description:
      "Các hoạt động của Quỹ từ khi khởi động luôn có sự đồng hành của con trai giáo sư Trần Văn Khê: GS-TS Trần Quang Hải (1940-2021)",
  },
];

const wrapperBlog = [
  { icon: "flaticon-vr-glasses", title: "Quo Maxime Qui Impedit Odio Soluta" },
  {
    icon: "flaticon-transformation",
    title: "Ut Nisi Ea Vero Itaque Error Magnam",
  },
  { icon: "flaticon-doctor-bag", title: "Quaerat Nobis Est" },
  { icon: "flaticon-open-book", title: "Deleniti Iure Ipsa Eos Eaque Under" },
];

const Introduction = () => {
  function isScrolledIntoView(elem) {
    const spliBox = document.querySelectorAll(elem);
    spliBox.forEach(myFunction);
    function myFunction(item, index) {
      console.log("splitbox", item);
      const docViewTop = window.scrollY;
      const docViewBottom = docViewTop + window.innerHeight;
      let elemTop = item.getBoundingClientRect().top + docViewTop;
      const elemBottom = elemTop + item.offsetHeight;
      if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
        item.classList.add("split-active");
      }
    }
  }
  window.addEventListener("scroll", () => {
    isScrolledIntoView(".split-box");
  });
  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dz-bnr-inr dz-bnr-inr-sm style-1 text-center"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        >
          <div className="container">
            <div className="dz-bnr-inr-entry">
              {/* <h1>HỌC BỔNG GIẢI THƯỞNG TRẦN VĂN KHÊ 2023</h1> */}
              {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>{maintitle}</Link></li>
                                <li className="breadcrumb-item active">{pagetitle}</li>
                            </ul>
                        </nav> */}
            </div>
          </div>
        </div>
        <section
          className="content-inner bg-light section-pattren1"
          style={{ paddingTop: "50px", paddingBottom: "20px" }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">GIỚI THIỆU</h2>
            </div>
          </div>
          {/* <img src={shape1} className="shape-1 move-1" alt="shape" /> */}
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          {/* <img src={shape6} className="shape-5 rotating" alt="shape" /> */}
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
        <section className="content-inner section-wrapper5 mobile-pb0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-head">
                  <h5 className="sub-title">Giới thiệu</h5>
                  <h3>QUỸ HỌC BỔNG TRẦN VĂN KHÊ</h3>
                  <p className="m-t20">
                    Kính gửi các bạn hữu gần xa, những người yêu âm nhạc dân tộc
                    và quý trọng Giáo sư âm nhạc Trần Văn Khê (1921-2015)
                    <br />
                    &emsp;Giáo sư Tiến sĩ Trần Văn Khê - Nhà dân tộc nhạc học
                    quốc tế nổi tiếng uyên bác và có nhiều đóng góp quý báu cho
                    nền âm nhạc truyền thống Việt Nam đã từ giã cuộc đời vào
                    ngày 24.6.2015.
                    <br />
                    &emsp;Trước khi mất, GS.TS Trần Văn Khê đã tiến hành lập vi
                    bằng về Di nguyện của ông, trong đó có việc thành lập Quỹ
                    Trần Văn Khê để khuyến khích những học sinh, những nhà
                    nghiên cứu và nghệ sĩ có thành tựu xuất sắc trong học tập,
                    nghiên cứu, phát huy giá trị nghệ thuật dân tộc truyền thống
                    Việt Nam.
                  </p>
                </div>
                {/* {iconBlog.map((data, index)=>(
                                    <div className="icon-bx-wraper box-hover style-2 m-b30" key={index}>
                                        <div className="icon-lg"> 
                                            <Link to={"/services-details"} className="icon-cell">
                                                <i className="flaticon-shield"></i>
                                            </Link> 
                                        </div>
                                        <div className="icon-content">
                                        <h5 className="dz-tilte m-b5 text-capitalize">{data.title}</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                        </div>
                                    </div>
                                ))} */}
              </div>
              <div className="col-lg-6 m-b30">
                <div className="dz-media" style={{ marginBottom: 0 }}>
                  <img
                    src={gstvk16}
                    alt="GS. Tran Van Khe"
                    style={{ borderRadius: "14px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-2" style={{ padding: 0 }}>
          <div className="container">
            <div className="row align-items-center project-bx left m-b50">
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media">
                  <div>
                    <img
                      src={gstvk14}
                      alt=""
                      className="app-1 mobile-hidden"
                      style={{ width: "36vw", borderRadius: "14px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <p>
                  &emsp; Ban tang lễ gồm những thành viên trong gia đình và bạn
                  bè thân thiết của Giáo sư hình thành để phối hợp cùng các cơ
                  quan của thành phố Hồ Chí Minh tổ chức lễ tang của giáo sư tại
                  nhà 32 Huỳnh Đình Hai, phường 24, quận Bình Thạnh. Ngay sau
                  tang lễ GS Trần Văn Khê, Ban tang lễ đã họp và quyết định
                  chuyển tên gọi thành Nhóm thân hữu Trần Văn Khê để cùng nhau
                  tiến hành thực hiện các hoạt động theo di nguyện của giáo sư
                  Trần Văn Khê, trong đó có việc quan trọng như sau: “…Riêng
                  tiền phúng điếu thì Ban tang lễ có thể sử dụng số tiền này để
                  lập một quỹ học bổng hoặc giải thưởng Trần Văn Khê để hàng năm
                  phát cho người được giải thưởng nghiên cứu về âm nhạc truyền
                  thống Việt Nam” (Mục 8).
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner" style={{ paddingTop: "40px" }}>
          <div className="container">
            <div className="section-head text-center">
              <h5 className="sub-title">THÀNH VIÊN</h5>
              <h3 className="title">Nhóm thân hữu Trần Văn Khê</h3>
              <p>
                Tiến hành thực hiện các hoạt động theo di nguyện của giáo sư
                Trần Văn Khê
              </p>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <NhomThanHuuTVK />
            </div>
          </div>
        </section>
        <section
          className="content-inner"
          style={{ paddingTop: "0px", marginTop: "-40px" }}
        >
          <div className="container">
            <blockquote className="block-quote style-1">
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                Sau một thời gian dài cố gắng tìm kiếm nguồn lực, từ năm 2019,
                Nhóm thân hữu Trần Văn Khê đã nhận được sự hợp tác tâm huyết và
                mạnh mẽ của Ban lãnh đạo Đại học Văn Lang: chịu trách nhiệm
                thành lập Quỹ Học bổng theo di nguyện của cố GS.TS Trần Văn Khê.
                Sau 4 lần điều chỉnh hồ sơ theo hướng dẫn của cơ quan chức năng,
                Quỹ Học bổng Trần Văn Khê cuối cùng cũng đã được thành lập tại
                Thành phố Hồ Chí Minh trong sự mong đợi và vui mừng của rất
                nhiều người.
                <br />
                Quyết định cấp Giấy phép thành lập số 680/QĐ-UBND cùng việc Công
                nhận Điều lệ Quỹ Học bổng Trần Văn Khê ngày 1/3/2021.
                <br />
                Quyết định Công nhận Quỹ đủ điều kiện hoạt động kèm theo công
                nhận Danh sách thành viên Hội đồng Quản lý Quỹ, số 1310/QĐ-UBND
                ngày 15/4/2021.
              </p>
              <cite> Nhóm thân hữu Trần Văn Khê </cite>
            </blockquote>
          </div>
        </section>
        <section
          className="content-inner-2 overlay-primary-dark about-wrapper2 bg-img-fix"
          style={{
            backgroundImage: "url(" + gstvk7 + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="about-bx2">
              <div className="row g-0">
                <div className="col-lg-4">
                  <div className="dz-media">
                    <img src={gstvk17} alt="" />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="dz-info">
                    <h3 className="title m-b30 m-md-b20">
                      QUỸ HỌC BỔNG TRẦN VĂN KHÊ
                    </h3>
                    <p className="text">
                      Quỹ Học bổng Trần Văn Khê được thành lập, đúng vào dịp kỷ
                      niệm 100 năm ngày sinh cố GS.TS Trần Văn Khê là một việc
                      làm rất có ý nghĩa. Giáo sư Trần Văn Khê trọn một đời theo
                      đuổi việc nghiên cứu và phát huy sâu rộng ra thế giới giá
                      trị quý báu của âm nhạc truyền thống Việt Nam. Các chuyên
                      đề nghiên cứu và đào tạo của ông về đờn ca tài tử, về cải
                      lương Việt Nam trong đối sánh với các loại hình âm nhạc,
                      kịch nghệ của các quốc gia châu Á (pansori của Triều Tiên,
                      kinh kịch của Trung Quốc, noh và kabuki của Nhật Bản) đã
                      được giới âm nhạc quốc tế đánh giá rất cao. Từ sự đánh giá
                      đó, nhiều bộ môn văn hóa - nghệ thuật dân tộc của Việt Nam
                      với sự góp sức trực tiếp và gián tiếp của GS Trần Văn Khê
                      đã được UNESCO công nhận là Di sản văn hóa phi vật thể thế
                      giới như Nhã nhạc Cung đình Huế, Không gian văn hóa Cồng
                      chiêng Tây Nguyên, Ca trù, Quan họ Bắc Ninh, Đàn ca tài tử
                      Nam Bộ…
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-2">
          <div className="container">
            <div className="row align-items-center project-bx left m-b30">
              <div className="col-lg-6 col-md-12">
                <div className="dz-media">
                  <div>
                    <img src={gstvk18} alt="" className="app-1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="dz-content">
                  <h3 className="title m-b15">TÔN CHỈ, MỤC ĐÍCH CỦA QUỸ</h3>
                  <p className="m-b0">
                    &emsp;Quỹ Trần Văn Khê hoạt động theo nguyên tắc không vì
                    lợi nhuận, tự nguyện, tự tạo vốn, tự trang trải kinh phí và
                    tự chịu trách nhiệm trước pháp luật bằng tài sản của Quỹ.
                    Mục đích của Quỹ là thông qua các hoạt động hỗ trợ, tài trợ,
                    xét trao giải thưởng và học bổng thường niên của Quỹ góp
                    phần cổ vũ việc gìn giữ, phát huy các giá trị âm nhạc truyền
                    thống Việt Nam theo di nguyện của Giáo sư Trần Văn Khê, phù
                    hợp với đường lối văn hóa của Nhà nước Việt Nam.
                    <br />
                    &emsp;Bên cạnh đó, Quỹ còn có những hoạt động khác nhằm hỗ
                    trợ việc nghiên cứu phát triên âm nhạc dân tộc và phát huy
                    các công trình nghiên cứu về âm nhạc dân tộc của Cố Giáo sư
                    Trần Văn Khê.
                    <br />
                    &emsp;Bằng việc sử dụng nguồn vốn hiện có của Quỹ và tiếp
                    nhận các tài sản được các tổ chức, cá nhân trong và ngoài
                    nước tài trợ, hiến tặng hoặc các hình thức khác theo quy
                    định của pháp luật, Quỹ Trần Văn Khê sẽ tạo nguồn vốn để
                    thực hiện được mục đích và nhiệm vụ được quy định trong Điều
                    lệ của Quỹ{" "}
                    <span style={{ color: "red" }}>
                      theo nguyên tắc công khai, minh bạch
                    </span>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row align-items-center project-bx right">
              <div className="col-lg-7 col-md-12 m-b30">
                <div className="dz-content">
                  <h3 className="title m-b15" style={{ fontSize: "26px" }}>
                    LĨNH VỰC HOẠT ĐỘNG CHÍNH CỦA QUỸ
                  </h3>
                  <ul>
                    <li>
                      ● Xét và tặng Giải thưởng Trần Văn Khê, Học bổng Trần Văn
                      Khê hàng năm.
                    </li>
                    <li>
                      ● Phối hợp với các đơn vị báo chí, xuất bản, nghiên cứu,
                      đào tạo, biểu diễn nghệ thuật tổ chức các hoạt động đúng
                      tôn chỉ, mục đích của Quỹ, trong đó có việc gây quỹ để duy
                      trì Quỹ.
                    </li>
                    <li>
                      ● Các hoạt động của Quỹ từ khi khởi động luôn có sự đồng
                      hành của con trai giáo sư Trần Văn Khê: GS-TS Trần Quang
                      Hải (1940-2021).
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 m-b30">
                <div className="dz-media">
                  <div>
                    <img
                      src={gstvk15}
                      alt=""
                      className="app-1"
                      style={{ width: "30vw" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={shape1} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
        <section className="content-inner">
          <div className="container">
            <div className="section-head text-center">
              <h5 className="sub-title">CẤU TRÚC</h5>
              <h3 className="m-b20">THÀNH VIÊN CỦA QUỸ TRẦN VĂN KHÊ</h3>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis no</p> */}
            </div>
            <div className="row">
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{ paddingRight: "4px" }}
                key={0}
              >
                <div
                  className="icon-bx-wraper box-hover style-2 m-b30"
                  style={{ padding: "16px", height: "90%" }}
                >
                  <div className="icon-lg">
                    <Link
                      className="icon-cell"
                      style={{ marginRight: "16px", cursor: "default" }}
                    >
                      <i className="flaticon-coins"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dz-tilte m-b10 text-capitalize">
                      Ban sáng lập Quỹ
                    </h5>
                    <p>
                      ● Kỹ sư Bùi Quang Độ (1946-2021), nguyên Chủ tịch Đại học
                      Văn Lang.
                      <br />
                      ● Nhà nghiên cứu Nguyễn Đắc Xuân.
                      <br />● Doanh nhân Lê Quốc Ân.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{ paddingLeft: "4px", paddingRight: "4px" }}
                key={1}
              >
                <div
                  className="icon-bx-wraper box-hover style-2 m-b30"
                  style={{ padding: "16px", height: "90%" }}
                >
                  <div className="icon-lg">
                    <Link
                      className="icon-cell"
                      style={{ marginRight: "16px", cursor: "default" }}
                    >
                      <i className="flaticon-handshake"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dz-tilte m-b10 text-capitalize">
                      Ban Giám Đốc
                    </h5>
                    <p>
                      ● Ông Dương Trọng Dật, Giám đốc điều hành
                      <br />● Bà Nguyễn Thế Thanh, Phó Giám đốc
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12" key={3}>
                <div
                  className="icon-bx-wraper box-hover style-2 m-b30"
                  style={{ padding: "16px" }}
                >
                  <div className="icon-lg">
                    <Link
                      className="icon-cell"
                      style={{ marginRight: "16px", cursor: "default" }}
                    >
                      <i className="flaticon-account"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dz-tilte m-b10 text-capitalize">
                      Hội đồng Quản lý Quỹ
                    </h5>
                    <p>
                      ● Kỹ sư Bùi Quang Độ, Chủ tịch.
                      <br />
                      ● Nhà báo Nguyễn Thế Thanh, Phó Chủ tịch.
                      <br />
                      ● Nhà báo Dương Trọng Dật, Viện trưởng Viện Nghiên cứu,
                      Đào tạo Văn hóa Nghệ thuật, thành viên Hội đồng Trường Đại
                      học Văn Lang, Giám đốc Quỹ Trần Văn Khê, thành viên.
                      <br />
                      ● Tiến sĩ Mai Mỹ Duyên, thành viên.
                      <br />● Ông Hoàng Sơn Điền, Giám đốc điều hành Đại học Văn
                      Lang, thành viên.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{ paddingLeft: "4px" }}
                key={2}
              >
                <div
                  className="icon-bx-wraper box-hover style-2 m-b30"
                  style={{ padding: "16px", height: "90%" }}
                >
                  <div className="icon-lg">
                    <Link
                      className="icon-cell"
                      style={{ marginRight: "16px", cursor: "default" }}
                    >
                      <i className="flaticon-open-book"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dz-tilte m-b10 text-capitalize">
                      Hội đồng Chuyên môn
                    </h5>
                    <p>
                      ● Giáo sư Trần Thế Bảo, Chủ tịch
                      <br />
                      ● Tiến sĩ Mai Mỹ Duyên, Phó Chủ tịch
                      <br />
                      ● Nhà báo Nguyễn Thế Thanh, thành viên
                      <br />
                      ● Nhà báo Dương Trọng Dật, thành viên
                      <br />● Tiến sĩ Lê Hồng Phước, thành viên
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{ paddingLeft: "4px" }}
                key={2}
              >
                <div
                  className="icon-bx-wraper box-hover style-2 m-b30"
                  style={{ padding: "16px", height: "90%" }}
                >
                  <div className="icon-lg">
                    <Link
                      className="icon-cell"
                      style={{ marginRight: "16px", cursor: "default" }}
                    >
                      <i className="flaticon-shield"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dz-tilte m-b10 text-capitalize">
                      Ban kiểm soát
                    </h5>
                    <p>
                      ● Ông Hoàng Sơn Điền
                      <br />
                      ● Bà Lê Ngọc Hân
                      <br />● Bà Châu Thị Hồng Lam
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="widget style-1 widget_refer">
              <h3 className="title">THÔNG TIN LIÊN HỆ</h3>
              <div className="widget-title">
                <h4 className="title" style={{ color: "#1B8271" }}>
                  Đại diện truyền thông
                </h4>
              </div>
              <p>Bà Nguyễn Thế Thanh, email: nbthethanh@gmail.com</p>
              <div className="widget-title">
                <h4 className="title" style={{ color: "#1B8271" }}>
                  Liên hệ quỹ học bổng Trần Văn Khê
                </h4>
              </div>
              <p>
                Email: tranvankhefoundation@gmail.com
                <br />
                Thư ký: Ngọc Hân 0782 78 78 28 (zalo, viber)
              </p>
            </div>
          </div>
        </section>
        {/* <section className="content-inner-2">
                    <div className="container">
                        <div className="section-head text-center">
                            <h2 className="title">Akcel News</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <NewsSlider />     
                    </div>
                </section> */}
        {/* <div className="call-action style-1 content-inner-1">
                    <div className="container">
                        <UpdateBlog />
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default Introduction;
