import React from 'react';
import {Link} from 'react-router-dom';
import musicIcon from "../assets/icons/gstvk/music-note.png";
import searchIcon from "../assets/icons/gstvk/search.png";
import textileIcon from "../assets/icons/gstvk/textile.png";
import historyIcon from "../assets/icons/gstvk/history.png";
import studentIcon from "../assets/icons/gstvk/student.png";
import writerIcon from "../assets/icons/gstvk/writer.png";
import managerIcon from "../assets/icons/gstvk/manager.png";

const wrapperBlog = [
    {icon: searchIcon, name:"Nguyễn Đắc Xuân", description: "Nhà nghiên cứu văn hóa - lịch sử"},
    {icon: managerIcon, name:"Nguyễn Thế Thanh", description: "Nguyên Phó Giám đốc Sở Văn hóa - Thể thao - Du lịch "},
    {icon: musicIcon, name:"Trần Quang Hải", description: "Tiến sĩ Âm nhạc, con trai Giáo sư Trần Văn Khê"},
    {icon: historyIcon, name:"Nguyễn Nhã", description: "Tiến sĩ sử học"},
    {icon: writerIcon, name:"Trần Bá Thùy", description: "Phu quân của nữ sĩ Tôn Nữ Hỷ Khương - người em tâm giao của Giáo sư Trần Văn Khê"},
    {icon: textileIcon, name:"Lê Quốc Ân", description: "Nguyên Chủ tịch Hiệp hội Dệt may Việt Nam"},
    {icon: studentIcon, name:"Lê Ngọc Hân", description: "Môn sinh giáo sư Trần Văn Khê"},
];

const NhomThanHuuTVK = () => {
    return (
        <>
           {wrapperBlog.map((item, index)=>(
                <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                    <div className="icon-bx-wraper style-1 text-center m-b30" style={{height: '90%'}}>
                        <div className="icon-lg m-b30"> <Link className="icon-cell" style={{cursor: 'default'}}>
                            {/* <i className={item.icon}></i> */}
                            <img src={item.icon} alt="Icon" />
                        </Link> </div>
                        <div className="icon-content">
                            <h5 className="dz-tilte text-capitalize"><Link to={"/project-categories"}>{item.name}</Link></h5>
                            <p>{item.description}</p>
                        </div>
                    </div>
                </div>
            ))}      
        </>
    );
};



export default NhomThanHuuTVK;